
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

import * as types from "@/store/types";

@Component({
    computed: {
        ...mapGetters({
            progressState: types.APP_PROGRESSBAR
        })
    }
})
export default class Overlay extends Vue {}
